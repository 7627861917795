import React, { useEffect, useState, useRef } from "react";
import { useParams, useSearchParams, useNavigate, useLocation, useNavigationType } from "react-router-dom";
import Page from "./components/Page";
import SurveyService from "./services/SurveyService";
import surveyResponseService from "./services/SurveyResponseService";
import { PageContextProvider } from "./context/PageContext";
import errorReporter from "./services/ErrorReporter";
import { removeSurveyFromLocalStorage } from "./components/StartSurvey";

function App() {
  const [surveyResponse, setSurveyResponse] = useState();
  const [page, setPage] = useState();
  const [survey, setSurvey] = useState();
  const [searchParams] = useSearchParams(); //react-router hook for "discovering" query params
  let params = useParams(); // url params
  let navigate = useNavigate();
  let navigationType = useNavigationType();
  let location = useLocation();
  const unsubSurveyResponseDoc = useRef();

  let linkedDevice = searchParams.get("linked"); //query param - returns true if ?linked=true is present and null if not

  useEffect(() => {
    if (!params.pageId) {
      return;
    }

    if (location.state === "fromAction" || navigationType === "POP") {
      surveyResponseService.update(params.surveyResponseId, {
        "state.currentPageId": params.pageId,
      });
      //added for https://activecomply.atlassian.net/browse/DEV-21
      setPage(survey?.pages[ params.pageId]);
    }
  }, [location, params.pageId, params.surveyResponseId, navigationType]);

  // Get the surveyResponse doc
  useEffect(() => {
    const fetchSurveyResponse = async () => {
      let unsub = surveyResponseService.listen(params.surveyResponseId, async (surveyResponseDoc) => {
        let localSurveyResponseDoc = surveyResponseDoc.data();

        if (localSurveyResponseDoc.state && localSurveyResponseDoc.state.currentPageId !== params.pageId) {
          navigate(`/${params.surveyResponseId}/${params.tokenId}/${localSurveyResponseDoc.state.currentPageId}`, {
            state: "fromDatabase",
          });
        } else {
          setSurveyResponse(localSurveyResponseDoc);
        }
      });

      return unsub;
    };

    fetchSurveyResponse()
      .then((unsub) => {
        unsubSurveyResponseDoc.current = unsub;
      })
      .catch(console.error);

    return function cleanup() {
      if (unsubSurveyResponseDoc.current) {
        unsubSurveyResponseDoc?.current();
      }
    };
  }, [params.surveyResponseId, params.pageId, params.tokenId, navigate]);

  const calculateIds = (surveyDoc) => {
    Object.keys(surveyDoc.pages).forEach((pageId) => {
      surveyDoc.pages[pageId].id = pageId;
    });

    Object.keys(surveyDoc.questions).forEach((questionId) => {
      surveyDoc.questions[questionId].id = questionId;
    });
  };

  // Once the surveyResponse doc is received, fetch the associated survey
  useEffect(() => {
    if (!surveyResponse) {
      return;
    }

    if (!survey && surveyResponse.surveyId) {
      // We can grab the survey now

      SurveyService.fetchSurvey(surveyResponse.surveyId)
        .then((res) => {
          calculateIds(res);
          setSurvey(res);
        })
        .catch((err) => console.error("survey error", err));
    }

    if (survey) {
      // We have the survey
      //build test

      if (surveyResponse?.state?.currentPageId) {
        if (surveyResponse.state.currentPageId === params.pageId) {
          let localPage = survey.pages[surveyResponse.state.currentPageId];
          const isLastPage =
            survey.pageOrder.indexOf(surveyResponse.state.currentPageId) === survey.pageOrder.length - 1;

          setPage(localPage);

          let isStateDirty = false;
          let dataToUpdate = { state: { ...surveyResponse.state } };

          if (localPage.setState) {
            Object.keys(localPage.setState).forEach((key) => {
              if (dataToUpdate.state[key] !== localPage.setState[key]) {
                dataToUpdate.state[key] = localPage.setState[key];
                isStateDirty = true;
              }
            });
          }

          if (isLastPage && !surveyResponse.state.isComplete) {
            dataToUpdate.state = {
              ...surveyResponse.state,
              locked: true,
              isComplete: true,
            };

            dataToUpdate.completedDate = new Date().toISOString();
            isStateDirty = true;
          }

          if (isStateDirty) {
            if (dataToUpdate?.state?.isComplete) {
              removeSurveyFromLocalStorage(surveyResponse.surveyId);
            }

            surveyResponseService.update(surveyResponse.id, {
              ...dataToUpdate,
            });
          }

          // if current page is the completed page, set completedDate and isComplete
          /*
          if (surveyResponse.state.currentPageId === "completed" && !surveyResponse.state.isComplete) {
            surveyResponseService.update(surveyResponse.id, {
              completedDate: new Date(),
              state: {
                ...surveyResponse.state,
                isComplete: true
              },
            });
          }
          */
        }
      } else {
        surveyResponseService.update(surveyResponse.id, {
          "state.currentPageId": survey.pageOrder[0],
        });
      }
    }
  }, [survey, surveyResponse, params.tokenId, navigate, params.pageId]);

  if (page) {
    return (
      <ErrorBoundary>
        <PageContextProvider>
          <Page page={page} survey={survey} surveyResponse={surveyResponse} linkedDevice={linkedDevice} />
        </PageContextProvider>
      </ErrorBoundary>
    );
  } else {
    return null;
  }
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: "" };
  }

  componentDidCatch(error) {
    this.setState({ error: `${error.name}: ${error.message}` });
  }

  render() {
    const { error } = this.state;
    if (error) {
      errorReporter.report(new Error(error));

      return (
        <div className="h-100 d-flex align-items-center justify-content-center">
          <div className="text-center">
            <h1>Oh boy...</h1>
            <h5 className="mb-5">This page just crashed</h5>
            <h6>Click back and try again?</h6>
          </div>
        </div>
      );
    } else {
      return <>{this.props.children}</>;
    }
  }
}

export default App;
